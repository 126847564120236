<template>
  <div class='container'>
    <div class="content" v-if="list.length>0">
      <div class="family" v-for="item in list" :key="item.id" @click="edit(item.id)">
        <p>{{ item.name }}</p>
        <van-icon name="arrow" size="4.8vw"/>
      </div>
    </div>
    <van-empty description="暂无家庭成员信息" v-else></van-empty>
    <div class="bottom">
      <button @click="$router.push({path:'add-family',query:{id:$route.query.id}})">新增家庭成员</button>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {profileUpdateV2} from '@/api/student.js'
export default {
  data(){
    return{
      formData: {
          id: '',
          sn: '', // 学籍号
          grade: '', // 年级
          studyClass: '', // 班级
          gender: '', // 性别
          area: '', // 校区
          birthday: '', // 出生日期
          nation: '', // 民族
          birth_place: '', // 籍贯
          situation: '', // 学习情况
          interest: [], // 兴趣
          interest_other: '', // 兴趣
          home_address: '', // 家庭地址
          health_status: '', // 健康状况
          psychological_diagnsis: '', // 心理诊断记录
          past_medical_history: [], // 过往身体病史
          past_medical_history_other: '', // 过往身体病史
          parent_relationship: '', // 父母关系
          family_atmosphere: '', // 家庭气氛
          family_economy: '', // 家庭经济情况
          other: '', // 其他情况
          f_name: '', // 父亲名字
          f_age: '', // 年龄
          f_occupation: '', // 职业
          f_characteristics: '', // 个人特点
          f_like_level: '', // 喜爱程度 1 喜欢 2 不喜欢
          m_name: '', // 母亲名字
          m_age: '', // 年龄
          m_occupation: '', // 职业
          m_characteristics: '', // 个人特点
          m_like_level: '', // 喜爱程度 1 喜欢 2 不喜欢
          study: [], // 学习经历
          event: [], // 重大生活事件
          home: [], // 家庭成员
          province: '', // 省
          city: '', // 市
          county: '' // 区
      },
      list:[]
    }
  },
  computed:{
    ...mapState(['student']),
  },
  created(){
    this.getDetail()
  },
  methods:{
    async getDetail(){
      const {id}=this.$route.query
      await this.$store.dispatch('getStudentDetail',id)
      this.formData=this.student
      this.list=this.formData.home
    },
    edit(id){
      this.$router.push({path:'add-family',query:{id:this.$route.query.id,homeId:id}})
    }
  }
}
</script>
<style lang='less' scoped>
.container{
  padding: 2.6667vw 0 16vw 0 ;
  min-height: 100%;
  .content{
    padding: 0 4vw;
    .family{
      padding: 4vw 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 4vw;
      font-weight: 550;
    }
  }
  .bottom{
    position: fixed;
    display: flex;
    align-items: center;
    padding: 0 4vw;
    bottom: 0;
    width: 100%;
    height: 16vw;
    button{
      width: 100%;
      color: #fff;
      height:13.8667vw;
      line-height: 13.8667vw;
      font-size: 4vw;
      border-radius: 13.3333vw;
      background: #5EDBC9;
    }
  }
}
</style>